<template>
    <v-container>
        <v-card slot="rightContainer" outlined>
            <v-card-title class="grey lighten-4">
                <b>HISTORICO PAGOS</b>

                <v-spacer />
                <v-col cols="12" sm="3">
                    <h5>
                        <v-btn class="mx-1" small color="green" dark @click="descargarArchivo"> DESCARGAR XLSX </v-btn>
                    </h5>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-menu ref="menu" v-model="menu3" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="auto">
                        <template #activator="{ on, attrs }">
                            <v-text-field v-model="fechaFiltro" hide-details="auto" dense label="FILTRAR POR RANGO - MES"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                        </template>
                        <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                            <v-spacer />
                            <v-btn text color="primary" @click="menu3 = false"> CERRAR </v-btn>
                            <v-btn color="primary" :loading="loadingLista" @click="getLista"> BUSCAR </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line
                        hide-details />
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table multi-sort :search="search" :items="_listaPagos" :headers="headers"
                            class="elevation-1" :items-per-page="20">
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { format, setDate, addDays, parseJSON, addMinutes } from 'date-fns'
import { exportXLSX } from '@/utils'
export default {
    data() {
        return {
            fechaFiltro: [format(setDate(new Date(), 1), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
            menu3: false,
            loadingLista: false,
            dataProducto: '',
            search: '',
            headers: [
                { text: 'id', value: 'id' },
                { text: 'Sede', value: 'nameSede' },
                { text: 'idVenta', value: 'idVenta' },
                { text: 'Estado', value: 'estado' },
                { text: 'Tipo Pago', value: 'descIdTipoPago' },
                { text: 'Tipo Banco', value: 'tipoBanco' },
                { text: 'Moneda', value: 'moneda' },
                { text: 'Monto', value: 'importe' },
                { text: 'Tipo Cambio', value: 'tipoCambio' },
                { text: 'Fecha', value: 'fechaPago' },
                { text: 'N Operacion', value: 'nroOperacion' },
                { text: 'Comprobante', value: 'tipoComprobante' }
            ]
        }
    },
    computed: {
        ...mapState('pagos', ['listClientPagos']),
        ...mapState('utilities', ['tipoBancoDestino', 'tipoMoneda', 'paymentMethod']),

        _listaPagos() {
            let newLista = []
            if (this.listClientPagos.length > 0) {
                this.listClientPagos.map((x) => {
                    const findBanco = this.tipoBancoDestino.find((element) => element.id == x.idBancoDestinoExternal)

                    newLista.push({
                        ...x,
                        descIdTipoPago: this.paymentMethod.find(z => z.id == x.idTipoPago).text,
                        tipoBanco: findBanco?.description || '',
                        moneda: this.tipoMoneda.find((element) => element.id === x.idTipoMoneda).value,
                        fechaPago: x.fechaPago,
                        estado: x.status ? 'ACTIVO' : 'ELIMINADO'
                    })

                })
            }
            console.log('newLista', newLista)
            return newLista
        }
    },
    methods: {
        async getLista() {
            this.loadingLista = true
            await this.$store.dispatch('pagos/pagosClientes', { fechaFiltro: this.fechaFiltro })

            this.loadingLista = false
            this.menu3 = false
        },
        descargarArchivo() {
            this.loading = true
            const headers = ['id', 'nameSede', 'idVenta', 'tipoBanco', 'moneda', 'importe', 'tipoCambio', 'fechaPago', 'nroOperacion', 'tipoComprobante']
            const filename = `Historial Pagos ${this.fechaFiltro[0]} _ ${this.fechaFiltro[1]}`
            exportXLSX({ headers, campos: headers, arrayData: this._listaPagos }, { filename })
            this.loading = false
        }
    },
    async mounted() {
        await this.$store.dispatch('sedes/getAllSedes')

        await this.getLista()
    }
}
</script>
